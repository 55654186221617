
body, textarea, select, input {
  font-family: "HanHei SC", "PingFang SC", "Avenir Next", "Avenir", "Helvetica Neue", "Helvetica", "Lantinghei SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", "SimSun", "sans-serif";
  background: #f6f6f6;
  font-size: 16px;
  color: #000;
}

body, button, p, pre {
  margin: 0;
}

a {
  text-decoration: none;
}
@primary-color: #1DA57A;