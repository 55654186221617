.ModuleAllow {
  height: 100vh;
  padding: 16px;

  .allow {
    height: 100%;
    background: white;

    .logo {
      display: flex;
      justify-content: center;
      background-color: white;
    }

    .content {
      min-height: 300px;
      padding: 16px;
      background-color: #fff;

      .title {
        font-size: 24px;
        color: #333;
        margin: 16px 0;
        text-align: center;
      }
    }

    .btn {
      padding: 20px;
      height: 200px;
    }
  }

  .success {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    .result {
      height: 300px;
    }

    .btn {
      height: 200px;
    }
  }
}
@primary-color: #1DA57A;